import DateRanger from "../DateRanger/locale/zh-CN";
import InputAmount from "../InputAmount/locale/zh-CN";
import Log from "../Log/locale/zh-CN";
import MemberSearch from "../MemberSearch/locale/zh-CN";
import SearchSelect from "../SearchSelect/locale/zh-CN";
import TagFilter from "../TagFilter/locale/zh-CN";
import Tour from "../Tour/locale/zh-CN";
export default {
  DateRanger: DateRanger,
  MemberSearch: MemberSearch,
  InputAmount: InputAmount,
  SearchSelect: SearchSelect,
  TagFilter: TagFilter,
  TechUITour: Tour,
  Log: Log
};