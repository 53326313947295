export default {
  alibaba: '阿里巴巴',
  board: '集团董事局',
  ant: '蚂蚁金服',
  lazada: 'Lazada',
  search: '搜索',
  total: '全部',
  checkAll: '全选',
  nextLevel: '下级',
  alreadySelect: '已选用户',
  placeholder: '请输入关键字搜索',
  team: '团队'
};