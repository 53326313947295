// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import './core/polyfill';
import 'antd/dist/reset.css';
import { renderClient } from '/Users/zhuyu/Desktop/projects/anzhener-downloadpage/node_modules/@umijs/renderer-react';
import { getRoutes } from './core/route';
import { createPluginManager } from './core/plugin';
import { createHistory } from './core/history';
import { ApplyPluginsType } from 'umi';
import '@@/core/overrides.css';
import { genMount as qiankun_genMount, genBootstrap as qiankun_genBootstrap, genUnmount as qiankun_genUnmount, genUpdate as qiankun_genUpdate } from '@@/plugin-qiankun-slave/lifecycles';
import '/Users/zhuyu/Desktop/projects/anzhener-downloadpage/src/.umi-production/plugin-ctoken/interceptor.ts';

const publicPath = "/";
const runtimePublicPath = true;

async function render() {
  const pluginManager = createPluginManager();
  const { routes, routeComponents } = await getRoutes(pluginManager);

  // allow user to extend routes
  await pluginManager.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: {
      routes,
      routeComponents,
    },
  });

  const contextOpts = pluginManager.applyPlugins({
    key: 'modifyContextOpts',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });

  const basename = contextOpts.basename || '/';
  const historyType = contextOpts.historyType || 'browser';

  const history = createHistory({
    type: historyType,
    basename,
    ...contextOpts.historyOpts,
  });

  return (pluginManager.applyPlugins({
    key: 'render',
    type: ApplyPluginsType.compose,
    initialValue() {
      const context = {
        routes,
        routeComponents,
        pluginManager,
        mountElementId: 'root',
        rootElement: contextOpts.rootElement || document.getElementById('root'),
        publicPath,
        runtimePublicPath,
        history,
        historyType,
        basename,
        __INTERNAL_DO_NOT_USE_OR_YOU_WILL_BE_FIRED: {"pureApp":false,"pureHtml":false},
        callback: contextOpts.callback,
      };
      const modifiedContext = pluginManager.applyPlugins({
        key: 'modifyClientRenderOpts',
        type: ApplyPluginsType.modify,
        initialValue: context,
      });
      return renderClient(modifiedContext);
    },
  }))();
}

import './plugin-moment2dayjs/runtime.tsx'
render();

    if (typeof window !== 'undefined') {
      window.g_umi = {
        version: '4.3.28',
      };
    }
    

const qiankun_noop = () => new Error('qiankun lifecycle is not available for server runtime!');
const isServer = typeof window === 'undefined';
export const bootstrap = isServer ? qiankun_noop: qiankun_genBootstrap(render);
export const mount = isServer ? qiankun_noop : qiankun_genMount('root');
export const unmount = isServer ? qiankun_noop : qiankun_genUnmount('root');
export const update = isServer ? qiankun_noop : qiankun_genUpdate();
// 增加 ssr 的判断
if (!isServer && !window.__POWERED_BY_QIANKUN__) {
  bootstrap().then(mount);
}
    

import { getUrlWithCtoken } from './plugin-ctoken/utils';
import { getRequestInstance } from './plugin-request/request';

const request = getRequestInstance();
request.interceptors.request.use((config: any) => {
  config.url = getUrlWithCtoken(config.url);
  return config;
});
