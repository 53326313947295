// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import { useOutlet, history } from '@alipay/bigfish';
import React from 'react';
import { ApplyPluginsType } from 'umi';

import { getPluginManager } from '@@/core/plugin';
import { getTernContainer } from './utils';
import { store } from './utils';

let themeConfig: any = undefined;
let themeLayout: any = undefined;

const createLayout: (Layout: any) => React.FC = (Layout) => () => {
  return React.createElement(Layout, null, useOutlet());
};

export const patchClientRoutes = ({ routes }: { routes: any }) => {
  if (themeConfig) {
    // 合并主题 layout 下的子路由
    const themeRoute = themeLayout?.routes?.[0];
    const themeChildren = themeRoute?.children ?? [];
    const children = routes.splice(0);
    children.push(...themeChildren);

    const rootLayout = React.createElement(createLayout(themeLayout), null);
    const withLayout = {
      id: themeRoute?.id,
      path: '/',
      element: rootLayout,
      children,
      routes: children,
    };
    routes.unshift(withLayout);
  }
};

export const render = async (before: any) => {
  const isMaster = !window.__POWERED_BY_QIANKUN__;
  if (isMaster) {
    await getTernContainer()?.register('master', async (ctx: any) => {
      if (ctx.theme) {
        const theme = ctx.site.presets?.find((e) => e.type === 'theme');
        const mergedProps = await getPluginManager().applyPlugins({
          key: 'prepareTheme',
          type: ApplyPluginsType.modify,
          initialValue: theme?.props,
        });
        themeConfig = await ctx.theme;

        store.set('app.props', { ...themeConfig });

        themeLayout = await themeConfig.initLayout(mergedProps, { history });
      }
    });
  }
  return before();
};
