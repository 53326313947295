// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/Users/zhuyu/Desktop/projects/anzhener-downloadpage/src/models/global';
import model_2 from '/Users/zhuyu/Desktop/projects/anzhener-downloadpage/src/.umi-production/plugin-initialState/@@initialState';
import model_3 from '/Users/zhuyu/Desktop/projects/anzhener-downloadpage/src/.umi-production/plugin-qiankun-slave/qiankunModel';

export const models = {
model_1: { namespace: 'global', model: model_1 },
model_2: { namespace: '@@initialState', model: model_2 },
model_3: { namespace: '@@qiankunStateFromMaster', model: model_3 },
} as const
