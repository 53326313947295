// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
// 获取燕鸥容器
export function getTernContainer() {
  return (window as any)?.__TERN_CONTAINER__?.container;
}

type ThemeKeys =
  | 'app.props'
  | 'theme.enable'
  | 'theme.instance'
  | 'theme.children'
  | 'theme.props'
  | 'theme.routes';

type AppKeys = 'theme.api';
export const store = new Map<ThemeKeys | AppKeys, any>();

type Defer = {
  promise: Promise<any>;
  resolve(value?: any): void;
};

// @ts-ignore
const defer: Defer = {};
defer.promise = new Promise((rs) => {
  defer.resolve = rs;
});

export const resumeThemeRender = () => {
  defer.resolve();
};

export const pauseThemeRender = async (fn: () => Promise<any>) => {
  // todo: expose return value
  await defer.promise;
  return await fn();
};
