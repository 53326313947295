/* eslint-disable */
// API 更新时间：2022-04-11 19:48:10
// API 唯一标识：6254156a42acfdceef5f5104
// 该文件由 OneAPI 自动生成，请勿手动修改！

import * as UserController from './UserController';
import * as WebDraftController from './WebDraftController';
export default {
  UserController,
  WebDraftController,
};
