// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import Cookie from '/Users/zhuyu/Desktop/projects/anzhener-downloadpage/node_modules/js-cookie/dist/js.cookie.js';

function isIP(host: string) {
  return !/[a-z]/i.test(host);
}

export function getBaseDomain(host?: string) {
  if (!host) return host;
  if (isIP(host)) {
    return host;
  }
  return host.split('.').slice(-2).join('.');
}

export function getToken() {
  // WHY?
  if (isIP(location.hostname)) {
    Cookie.remove('ctoken');
  }

  // 再尝试获得 ctoken
  let ctoken = Cookie.get('ctoken');
  // 要是有 ctoken 则返回
  if (ctoken) {
    return ctoken;
  }

  // 再尝试获得 _CHIPS-ctoken
  ctoken = Cookie.get('_CHIPS-ctoken');
  // 要是有 _CHIPS-ctoken 则返回
  if (ctoken) {
    return ctoken;
  }

  // 要是没有，则在主域下加上 ctoken
  ctoken = 'bigfish_ctoken_' + (+new Date()).toString(22);
  const domain = getBaseDomain(location.hostname);
  /*
      domain               cookie
      127.0.0.1:7001       127.0.0.1
      localhost:7001       localhost
      dev.alipay.net:7001  .alipay.net
      alipay.net           .alipay.net
      bigfish.alipay.com   .alipay.com
  */
  Cookie.set('ctoken', ctoken, { domain });
  return ctoken;
}

const a =
  typeof document !== 'undefined' ? document.createElement('a') : undefined;

function getHostName(url: string) {
  if (a) {
    a.href = url;
    return a.hostname;
  }
}

export function withCtoken(url: string, hostname: string) {
  // 要是访问 http url
  if (/^(https?:)?\/\//.test(url)) {
    const targetHostname = getHostName(url);
    // 要是 hostname 相同会带上 ctoken
    if (targetHostname === hostname) {
      return true;
    // 要是主域相同也可带上 ctoken
    } else if (getBaseDomain(targetHostname) === getBaseDomain(hostname)) {
      return true;
    }
  // 要是访问的不是 http url（比如 /api) 带上 ctoken
  } else {
    return true;
  }
  return false;
}

export function getUrlWithCtoken(url: string) {
  if (withCtoken(url, location.hostname)) {
    const ctoken = getToken();
    const str = url.indexOf('?') > -1 ? '&' : '?';
    return `${url}${str}ctoken=${encodeURIComponent(ctoken)}`;
  }
  return url;
}
