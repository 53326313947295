// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from "@alipay/bigfish/react";

let id;

export default class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    isFirstTriggered: true,
  };

  // handle fallback UI rendering
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      isFirstTriggered: false
    };
  }

  componentDidCatch(error, errorInfo) {
    const errBounEvt = new CustomEvent('bigfish-tern:errBounEvt', {
      detail: {
        error,
        stack: errorInfo.componentStack,
        isFirstTriggered: this.state.isFirstTriggered,
        yuyanId: id, // 发生异常的应用雨燕ID
        domNum: -1, // 当被错误边界拦截时，一定DOM树已经被卸载了
      },
      bubbles: false,
    });
    window.dispatchEvent(errBounEvt);
    throw error;
  }

  render() {
    return this.state.hasError ? null : this.props.children;
  }
}
