// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from '@alipay/bigfish/react';
import ErrorBoundary from './errorBoundary.tsx';

let id;
id = '180020010001273131';

export const rootContainer = container => {
  return React.createElement(ErrorBoundary, null, container);
};

export const onRouteChange = ({ routes, matchedRoutes, action, basename, isFirst }) => {
  const isCurrentApp = window.location.pathname.startsWith(basename);
  if (!isCurrentApp) {
    return; // 如果不是当前应用就不执行后面的操作。子应用间切换时，被卸载的子应用的onRouteChange也会触发
  }

  // umi - isFirst 标记：页面刷新。暂时在非刷新状态下触发 onRouteChange 时无脑给燕鸥框架发送事件
  if (!isFirst) {
    const evt = new CustomEvent('bigfish-tern:routeChangeEvt', {
      detail: {
        error: null,
        yuyanId: id,
      },
      bubbles: false,
    });
    window.dispatchEvent(evt);
  }
};
