// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';
import { StyleSheetManager } from '/Users/zhuyu/Desktop/projects/anzhener-downloadpage/node_modules/@umijs/plugins/node_modules/styled-components';

import { styledComponents as styledComponentsConfig } from '@/app';
export function rootContainer(container) {
  const scConfig =
    typeof styledComponentsConfig === 'function'
      ? styledComponentsConfig()
      : styledComponentsConfig;
  const globalStyle = scConfig.GlobalStyle ? <scConfig.GlobalStyle /> : null;
  const inner = (
    <>
      {globalStyle}
      {container}
    </>
  );
  
  return (
    <StyleSheetManager {...{"disableCSSOMInjection":true}}>
      {inner}
    </StyleSheetManager>
  );
  
}
