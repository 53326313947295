export default {
  today: '今天',
  yesterday: '昨天',
  tomorrow: '明天',
  thisWeek: '本周',
  lastWeek: '上周',
  nextWeek: '下周',
  thisMonth: '本月',
  lastMonth: '上月',
  nextMonth: '下月',
  thisQuarter: '本季度',
  lastQuarter: '上季度',
  nextQuarter: '下季度',
  thisYear: '今年',
  lastYear: '去年',
  nextYear: '明年'
};